import React from 'react';
import {
    TextField,
    Show,
    TabbedShowLayout,
    Tab,
    ReferenceField,
    DateField
} from 'react-admin';
import {ControllerPropsInterface} from "interfaces/ControllerPropsInterface";
import Loader from "components/Loader";

import {Choose, Otherwise, When} from 'typings/tsx-control-statements.d';
import {withServerConstants} from "../../providers/ServerConstantsProvider";

const TrailReviewShow = (props: ControllerPropsInterface) => <Choose>
    <When condition={true}>
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label="general">
                    <TextField source="id" label="Review Id"/>
                    <ReferenceField source="trail_id" reference="trail" emptyText={'N/A'}><TextField
                        source="id"/></ReferenceField>
                    <ReferenceField source="user_id" reference="user" emptyText={'N/A'}><TextField
                        source="user_id"/></ReferenceField>
                    <TextField source="rating"/>
                    <TextField source="message"/>
                    <DateField source="created_at" showTime/>
                </Tab>
            </TabbedShowLayout>
        </Show>
    </When>
    <Otherwise>
        <Loader/>
    </Otherwise>
</Choose>


export default withServerConstants(TrailReviewShow);
