import React from 'react';
import {Filter, NumberInput, TextInput} from 'react-admin';

const UserActivityFilter = (props: any) => {
  return (
    <Filter {...props}>
        <TextInput source="id" alwaysOn resettable />
        <TextInput source="user_id" alwaysOn resettable />
        <NumberInput
            source="distance_from"
            min="0"
            step="1"
            resettable
            alwaysOn
        />
        <NumberInput
            source="distance_to"
            min="1"
            step="1"
            resettable
            alwaysOn
        />
    </Filter >
  );
};

export default UserActivityFilter;
