import React from 'react';
import {
    ReferenceField,
    ShowButton,
    TextField,
    DateField,
} from 'react-admin';
import List from 'components/List';
import {ControllerPropsInterface} from "interfaces/ControllerPropsInterface";
import Loader from "components/Loader";

import {Choose, If, Otherwise, When} from 'typings/tsx-control-statements.d';
import {withServerConstants} from "../../providers/ServerConstantsProvider";
import TrailReviewFilter from "../../filters/TrailReview/TrailReviewFilter";
import TrailReviewPanel from "../../panels/TrailReview/TrailReviewPanel";

const rowStyle = (record: any) => ({
    borderLeftColor: '#333',
    borderLeftWidth: 10,
    borderLeftStyle: 'solid',
});

const TrailReview = (props: ControllerPropsInterface) => (
    <Choose>
        <When condition={props.permissions}>
            <List
                filters={<TrailReviewFilter serverConstants={props.serverConstants}/>}
                rowStyle={(record) => rowStyle(record)}
                sort={{field: 'id', order: 'DESC'}}
                expand={<TrailReviewPanel/>}
                {...props}
            >
                <TextField source="id" label="Review Id"/>
                <ReferenceField source="trail_id" reference="trail" emptyText={'N/A'} label="Trail id">
                    <TextField source="id"/>
                </ReferenceField>
                <ReferenceField source="trail_id" reference="trail" emptyText={'N/A'} label="Trail name">
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceField source="user_id" reference="user" emptyText={'N/A'} label="User id">
                    <TextField source="id"/>
                </ReferenceField>
                <ReferenceField source="user_id" reference="user" emptyText={'N/A'} label="User name">
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source="rating"/>
                <DateField source="created_at" showTime/>
                <ShowButton/>
            </List>
        </When>
        <Otherwise>
            <Loader/>
        </Otherwise>
    </Choose>
)


export default withServerConstants(TrailReview);
