import {
    TextInput,
    required,
    ReferenceInput,
    AutocompleteInput,
    FormDataConsumer,
    NumberInput,
    SelectInput,
    ArrayInput,
    SimpleFormIterator,
    minValue, maxValue
} from "react-admin";
import {withServerConstants} from "providers/ServerConstantsProvider";
import React from "react";
import SimpleFormCustom from "../SimpleFormCustom";
import {ActionType} from "../../interfaces/CommonInterface";
import {EditableImage} from "../../components/Image/EditableImageField";
import {getChoicesList} from "../../utils/common";


const ParkForm = (props: any) => {
    return (
        <SimpleFormCustom
            isLoaded={props.actionType == ActionType.ADD || !!props.record}
            {...props}
        >
            <TextInput source="name" validate={[required('Name is required')]}/>
            <SelectInput
                key="Type"
                source="type"
                choices={getChoicesList(Object.values(props.serverConstants?.park_type?.items))}
                validate={[required('Type is required')]}
            />
            <TextInput
                multiline
                rows={5}
                source="description"
                fullWidth
                validate={[required('Description is required')]}
            />
            <TextInput
                multiline
                rows={5}
                source="moderator_notes"
                fullWidth
            />
            <ReferenceInput label="Country" source="country_id" reference="country"
                            sort={{}}
                            validate={[required('Country is required')]}
            >
                <AutocompleteInput optionText="name" resettable={true}/>
            </ReferenceInput>
            <FormDataConsumer>
                {({formData}) => (
                    <ReferenceInput label="Region" source="state_id" reference="state"
                                    sort={{}}
                                    filter={formData.country_id ? { country_id: formData.country_id } : {}}
                                    filterToQuery={searchText => (searchText ? {name: searchText} : {})}
                                    disabled={!formData.country_id}
                                    validate={[required('Region is required')]}
                    >
                        <AutocompleteInput optionText="name" resettable={true}/>
                    </ReferenceInput>
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {({formData}) => (
                    <ReferenceInput label="City" source="city_id" reference="city"
                                    sort={{}}
                                    filter={formData.state_id ? { state_id: formData.state_id } : {}}
                                    filterToQuery={searchText => (searchText ? {name: searchText} : {})}
                                    disabled={!formData.state_id}
                    >
                        <AutocompleteInput optionText="name" resettable={true} loaded={true}/>
                    </ReferenceInput>
                )}
            </FormDataConsumer>
            <EditableImage source="preview_img" {...props} label="Image" aspect={1.74}/>
            <TextInput source="slug" validate={[required('Slug is required')]}/>
            <NumberInput source="latitude"
                         min="-90"
                         max="90"
                         validate={[
                             required('Latitude is required'),
                             minValue(-90, 'Must be more than -90'),
                             maxValue(90, 'Must be less than 90'),
                         ]} />
            <NumberInput source="longitude"
                         min="-180"
                         max="180"
                         validate={[
                             required('Longitude is required'),
                             minValue(-180, 'Must be more than -180'),
                             maxValue(180, 'Must be less than 180'),
                         ]} />
            <NumberInput source="popularity"/>
            <NumberInput source="trails_count_goal"
                         label="Trails goal"
                         validate={[
                            required('Trail goal is required'),
                            minValue(1, 'Must be more than 0'),
                         ]} />
            {/*<ArrayInput source="images">*/}
            {/*    <SimpleFormIterator>*/}
            {/*        <EditableImage source="preview_img" {...props} label="Image" aspect={1.74}/>*/}
            {/*    </SimpleFormIterator>*/}
            {/*</ArrayInput>*/}
        </SimpleFormCustom>
    )
};

export default withServerConstants(ParkForm)
