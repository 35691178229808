import * as React from "react";
import {useRecordContext} from "react-admin";
import get from "lodash/get";
import {makeStyles} from "@material-ui/core/styles";
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles({
    downloadLink: {
        position: 'relative',
        display: 'inline-block',
        textDecoration: 'none', // Remove underline from the link
    },
    image: {
        width: '100%', // Adjust as needed
        height: 'auto', // Ensure the aspect ratio is maintained
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Black overlay with alpha 0.5
        opacity: 0,
        transition: 'opacity 0.3s ease', // Add transition for smooth effect
    },
    downloadIcon: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity: 0,
        transition: 'opacity 0.3s ease',
        display: 'flex'
    },
    downloadLinkHover: {
        width: 300,
        margin: '0 15px 25px 0',
        '&:hover $image': {
            filter: 'blur(2px)',
            opacity: 0.6,
            border: '5px #fe2b60 solid'
        },
        '&:hover $downloadIcon': {
            opacity: 1,
        },
    },
});

const ImagesList = (props: any) => {
    const classes = useStyles();
    const {source} = props;
    const record = useRecordContext(props);
    const data = get(record, source);
    return (
        <>
            {data ? data.map(function (item: any) {
                return <a download href={item} className={`${classes.downloadLink} ${classes.downloadLinkHover}`}>
                    <img src={item} alt="Image" className={classes.image} />
                    <div className={classes.overlay}></div>
                    <div className={classes.downloadIcon}>
                        <SaveIcon/><span>Download</span>
                    </div>
                </a>
            }) : 'N/A'}
        </>
    )
}
export default ImagesList;
