import React from 'react';
import {
    TextField,
    Show,
    TabbedShowLayout,
    Tab,
    NumberField,
    FunctionField,
    ReferenceField,
    ImageField,
    DateField
} from 'react-admin';
import {ControllerPropsInterface} from "interfaces/ControllerPropsInterface";
import Loader from "components/Loader";

import {Choose, If, Otherwise, When} from 'typings/tsx-control-statements.d';
import {withServerConstants} from "../../providers/ServerConstantsProvider";
import TrailMap from "../../components/Map/TrailMap";
import {Record} from "ra-core";
import {toHoursAndMinutes, toKm} from "../../utils/common";
import {isAdmin} from "../../utils/authProviderProvider";
import ImagesList from "../../components/ImagesList/ImagesList";

const TrailShow = (props: ControllerPropsInterface) => (
    <Choose>
        <When condition={props.permissions}>
            <If condition={isAdmin(props.permissions)}>
                <Show {...props}>
                    <TabbedShowLayout>
                        <Tab label="general">
                            <TextField source="id" />
                            <TextField source="name" emptyText={'N/A'}/>
                            <ImageField source="image_medium" emptyText={'N/A'}/>
                            <ReferenceField source="user_id" reference="user" emptyText={'N/A'}><TextField source="id" /></ReferenceField>
                            <ReferenceField source="trail_id" reference="trail" emptyText={'N/A'}><TextField source="id" /></ReferenceField>
                            <TextField source="country" emptyText={'N/A'}/>
                            <TextField source="state" emptyText={'N/A'}/>
                            <TextField source="city" emptyText={'N/A'}/>
                            <TextField source="park" emptyText={'N/A'}/>
                            <TextField source="activity_type" />
                            <FunctionField
                                source="total_time"
                                render={(record: Record | undefined) => toHoursAndMinutes(record?.total_time)}
                            />
                            <FunctionField
                                source="distance"
                                render={(record: Record | undefined) => toKm(record?.distance) + 'km'}
                            />
                            <FunctionField
                                source="avg_speed"
                                emptyText={'0'}
                                render={(record: Record | undefined) => `${toKm(record?.avg_speed)}km/h`}
                            />
                            <FunctionField
                                source="avg_pace"
                                emptyText={'0'}
                                render={(record: Record | undefined) => toHoursAndMinutes(record?.avg_pace)}
                            />
                            <NumberField source="calories_burned" emptyText={'0'}/>
                            <NumberField source="elevation_gain" emptyText={'0'}/>
                            <NumberField source="elevation_loss" emptyText={'0'}/>
                            <DateField source="created_at" showTime/>
                            <DateField source="updated_at" showTime/>
                        </Tab>
                        <Tab label="map">
                            <TrailMap {...props}/>
                        </Tab>
                        <Tab label="photos">
                            <ImagesList source="photos"/>
                        </Tab>
                    </TabbedShowLayout>
                </Show>
            </If>
        </When>
        <Otherwise>
            <Loader/>
        </Otherwise>
    </Choose>
)


export default withServerConstants(TrailShow);
