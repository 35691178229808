import * as React from "react";
import {Admin, Resource} from 'react-admin';

import customRoutes from 'controllers/customRoutes';
import rootReducer from 'redux/rootReducer';

// custom providers (connect with amplify-aws and react-admin)
import dataProvider from 'providers/DataProvider';
import authProvider from 'providers/AuthProvider';

// providers for getting common data
import ServerConstantsProvider from 'providers/ServerConstantsProvider';

// custom layout
import MainLayout from 'layouts/MainLayout';

// routes
import Trail from "./controllers/Trail/Trail";
import TrailShow from "./controllers/Trail/TrailShow";
import TrailEdit from "./controllers/Trail/TrailEdit";
import ExternalTrail from "./controllers/Trail/ExternalTrail";
import ExternalTrailShow from "./controllers/Trail/ExternalTrailShow";
import Region from "./controllers/Region";
import RegionShow from "./controllers/Region/RegionShow";
import RegionAdd from "./controllers/Region/RegionAdd";
import RegionEdit from "./controllers/Region/RegionEdit";
import City from "./controllers/City";
import CityShow from "./controllers/City/CityShow";
import CityAdd from "./controllers/City/CityAdd";
import CityEdit from "./controllers/City/CityEdit";
import Park from "./controllers/Park";
import ParkShow from "./controllers/Park/ParkShow";
import ParkAdd from "./controllers/Park/ParkAdd";
import ParkEdit from "./controllers/Park/ParkEdit";
import TrailAdd from "./controllers/Trail/TrailAdd";
import Country from "./controllers/Country";
import CountryShow from "./controllers/Country/CountryShow";
import CountryEdit from "./controllers/Country/CountryEdit";
import User from "./controllers/User/User";
import UserShow from "./controllers/User/UserShow";
import UserEdit from "./controllers/User/UserEdit";
import UserAdd from "./controllers/User/UserAdd";
import UserActivity from "./controllers/User/UserActivity";
import UserActivityShow from "./controllers/User/UserActivityShow";
import TrailReview from "./controllers/TrailReview/TrailReview";
import TrailReviewShow from "./controllers/TrailReview/TrailReviewShow";

const App = () => (
    <ServerConstantsProvider>
        <Admin
            customReducers={rootReducer}
            customRoutes={customRoutes}
            dataProvider={dataProvider}
            authProvider={authProvider}
            layout={MainLayout}
        >
            <Resource
                name="trail"
                list={Trail}
                show={TrailShow}
                edit={TrailEdit}
                create={TrailAdd}
                options={{ label: 'Main Trails' }}
            />
            <Resource
                name="external-trail"
                list={ExternalTrail}
                show={ExternalTrailShow}
                options={{ label: 'External Trails' }}
            />
            <Resource
                name="country"
                list={Country}
                show={CountryShow}
                edit={CountryEdit}
                options={{ label: 'Countries' }}
            />
            <Resource
                name="state"
                list={Region}
                show={RegionShow}
                edit={RegionEdit}
                create={RegionAdd}
                options={{ label: 'Regions' }}
            />
            <Resource
                name="city"
                list={City}
                show={CityShow}
                edit={CityEdit}
                create={CityAdd}
                options={{ label: 'Cities' }}
            />
            <Resource
                name="park"
                list={Park}
                show={ParkShow}
                edit={ParkEdit}
                create={ParkAdd}
                options={{ label: 'Parks' }}
            />
            <Resource
                name="user"
                list={User}
                show={UserShow}
                edit={UserEdit}
                create={UserAdd}
                options={{ label: 'Users' }}
            />
            <Resource
                name="user-activity"
                list={UserActivity}
                show={UserActivityShow}
                options={{ label: 'User activities' }}
            />
            <Resource
                name="trail-review"
                list={TrailReview}
                show={TrailReviewShow}
                options={{ label: 'Trail Reviews' }}
            />
        </Admin>
    </ServerConstantsProvider>
)

export default App;
