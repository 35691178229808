import React from 'react';
import {
    TextField,
    Show,
    TabbedShowLayout,
    RichTextField,
    Tab,
    NumberField,
    BooleanField,
    FunctionField,
    ReferenceField,
    ImageField,
    Datagrid,
    ArrayField,
    DateField
} from 'react-admin';
import {ControllerPropsInterface} from "interfaces/ControllerPropsInterface";
import Loader from "components/Loader";

import {Choose, Otherwise, When} from 'typings/tsx-control-statements.d';
import {withServerConstants} from "../../providers/ServerConstantsProvider";
import TrailMap from "../../components/Map/TrailMap";
import ChipList from "../../components/ChipList/ChipList";
import TrailPreviewMap from "../../components/Map/TrailPreviewMap";

const TrailShow = (props: ControllerPropsInterface) => <Choose>
    <When condition={true}>
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label="general">
                    <TextField source="name"/>
                    <TextField source="created_by"/>
                    <TextField source="last_updated_by"/>
                    <ReferenceField source="country_id" reference="country">
                        <TextField source="name"/>
                    </ReferenceField>
                    <FunctionField source="status" render={
                        ({status}: any) => props.serverConstants?.trail_status?.items[status]?.title || status}
                    />
                    <ReferenceField source="state_id" reference="state">
                        <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField source="city_id" reference="city">
                        <TextField source="name"/>
                    </ReferenceField>
                    <RichTextField source="description" emptyText={'N/A'}/>
                    <RichTextField source="moderator_notes" emptyText={'N/A'}/>
                    <ChipList
                        source="activities"
                        sortable={false}
                        normalizer={(obj: any) => props.serverConstants?.activity_type?.items[obj]?.title || obj}
                    />
                    <BooleanField source="is_closed"/>
                    <BooleanField source="is_private_property"/>
                    <NumberField source="avg_rating" emptyText={'N/A'}/>
                    <NumberField source="popularity" emptyText={'N/A'}/>
                    <ImageField source="thumb_img" emptyText="N/A"/>
                    <ImageField source="map_preview_thumb" emptyText="N/A"/>
                    <ImageField source="origin_img" emptyText="N/A"/>
                    <ImageField source="map_preview_origin" emptyText="N/A"/>
                </Tab>
                <Tab label="stats">
                    <FunctionField source="route_type" render={
                        ({route_type}: any) => props.serverConstants?.route_type?.items[route_type]?.title || route_type
                    }/>
                    <FunctionField source="difficulty" render={
                        ({difficulty}: any) => props.serverConstants?.difficulty?.items[difficulty]?.title || difficulty
                    }/>
                    <NumberField source="length"/>
                    <NumberField source="duration"/>
                    <NumberField source="elevation_gain"/>
                    <NumberField source="latitude"/>
                    <NumberField source="longitude"/>
                    <TextField source="bbox"/>
                </Tab>
                <Tab label="reviews">
                    <ArrayField source="reviews" sortable={true}>
                        <Datagrid size="small">
                            <TextField source="id" label="Review Id"/>
                            <ReferenceField source="user_id" reference="user" emptyText={'N/A'}>
                                <TextField source="name"/>
                            </ReferenceField>
                            <TextField source="rating"/>
                            <TextField source="message" emptyText="-"/>
                            <DateField source="created_at" showTime/>
                        </Datagrid>
                    </ArrayField>
                </Tab>
                <Tab label="map">
                    <TrailMap/>
                </Tab>
                <Tab label="preview">
                    <TrailPreviewMap/>
                </Tab>
            </TabbedShowLayout>
        </Show>
    </When>
    <Otherwise>
        <Loader/>
    </Otherwise>
</Choose>


export default withServerConstants(TrailShow);
