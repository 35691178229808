import AbstractNormalizer from './AbstractNormalizer';

class UserNormalizer extends AbstractNormalizer {
    create(payload: any) {
        const data = payload;
        return data;
    }

    update(payload: any) {
        const data = super.beforeUpdate(payload, true);
        delete data.total_elevation_gain;
        delete data.total_calories_burned;
        delete data.total_distance;
        delete data.product_list;
        delete data.language;
        delete data.country;
        delete data.email_consent;
        delete data.last_session_at;
        delete data.total_activities;
        delete data.collections_order;
        delete data.last_completed_activity_at;
        delete data.reteno_contact_id;
        delete data.timezone;
        delete data.locale;
        delete data.total_collections;
        delete data.client_version;
        delete data.favorites_id;
        delete data.goal;
        delete data.planned_routes_id;
        delete data.platform;
        delete data.special;
        delete data.support_email;
        delete data.terrain;
        delete data.total_favorites;
        delete data.total_personal_collections;
        delete data.total_planned_routes;
        delete data.validation_status;
        delete data.bmi_group;
        delete data.fitlevel;
        delete data.health;
        delete data.safety;
        delete data.trail_level;
        delete data.walking;
        return data;
    }
}

export default new UserNormalizer();
