import React from 'react';
import {Filter, FormDataConsumer, NumberInput, TextInput, DateTimeInput} from 'react-admin';
import {renderFilterReferenceInput, renderFilterSelectInput} from "../FiltersUtils";
import {getChoicesList} from "../../utils/common";

const TrailFilter = (props: any) => {
  const { serverConstants, ...rest } = props;

  return (
    <Filter {...rest}>
        {renderFilterSelectInput('status', getChoicesList(Object.values(serverConstants?.trail_status?.items)))}
        {renderFilterSelectInput('source', getChoicesList(Object.values(serverConstants?.trail_source?.items)))}
        {renderFilterSelectInput('activity_type', getChoicesList(Object.values(serverConstants?.activity_type?.items)))}
        {renderFilterReferenceInput('Country', 'country_id', 'country')}
        <FormDataConsumer form={'filterForm'} alwaysOn source='state_id'>
            {({ formData }) => renderFilterReferenceInput(
                'Region',
                'state_id',
                'state',
                props.filterValues.country_id ? { country_id: props.filterValues.country_id } : {},
                (searchText: any) => (searchText ? {name: searchText} : {}),
                !formData.country_id
            )}
        </FormDataConsumer>
        <FormDataConsumer form={'filterForm'} alwaysOn source='city_id'>
            {({ formData }) => renderFilterReferenceInput(
                'City',
                'city_id',
                'city',
                props.filterValues.state_id ? { state_id: props.filterValues.state_id } : {},
                (searchText: any) => (searchText ? {name: searchText} : {}),
                !formData.state_id
            )}
        </FormDataConsumer>
        <FormDataConsumer form={'filterForm'} alwaysOn source='park_id'>
            {({ formData }) => renderFilterReferenceInput(
                'Park',
                'park_id',
                'park',
                props.filterValues.state_id ? { state_id: props.filterValues.state_id } : {},
                (searchText: any) => (searchText ? {name: searchText} : {}),
                !formData.state_id
            )}
        </FormDataConsumer>
        <TextInput source="id" alwaysOn resettable />
        <TextInput source="name" alwaysOn resettable />
        <TextInput source="moderator_notes" alwaysOn resettable />
        <TextInput source="created_by" resettable alwaysOn/>
        <TextInput source="last_updated_by" resettable alwaysOn/>
        <NumberInput
            source="length_from"
            min="1"
            step="1"
            resettable
            alwaysOn
        />
        <NumberInput
            source="length_to"
            min="1"
            step="1"
            resettable
            alwaysOn
        />
        <DateTimeInput source="created_at_from" showTime alwaysOn/>
        <DateTimeInput source="created_at_to" showTime alwaysOn/>
        <DateTimeInput source="updated_at_from" showTime alwaysOn/>
        <DateTimeInput source="updated_at_to" showTime alwaysOn/>
        {renderFilterSelectInput('route_type', getChoicesList(Object.values(serverConstants?.route_type?.items)))}
    </Filter >
  );
};

export default TrailFilter;
