import React from 'react';
import {
    EditButton,
    ReferenceField,
    ShowButton,
    TextField,
    EmailField,
    NumberField,
    DateField,
    BooleanField,
    ImageField
} from 'react-admin';
import List from 'components/List';
import {ControllerPropsInterface} from "interfaces/ControllerPropsInterface";
import Loader from "components/Loader";

import {Choose, Otherwise, When, If} from 'typings/tsx-control-statements.d';
import {withServerConstants} from "../../providers/ServerConstantsProvider";
import UserFilter from "../../filters/User/UserFilter";
import {isAdmin} from "../../utils/authProviderProvider";
import ChipList from "../../components/ChipList/ChipList";

const rowStyle = (record: any) => ({
    borderLeftColor: '#333',
    borderLeftWidth: 10,
    borderLeftStyle: 'solid',
});

const User = (props: ControllerPropsInterface) => (
    <Choose>
        <When condition={props.permissions}>
            <If condition={isAdmin(props.permissions)}>
                <List
                    filters={<UserFilter serverConstants={props.serverConstants}/>}
                    rowStyle={(record) => rowStyle(record)}
                    sort={{field: 'id',order: 'DESC'}}
                    {...props}
                >
                    <TextField source="id" />
                    <ImageField source="profile_image_url" emptyText={'N/A'}/>
                    <EmailField source="email" emptyText={'N/A'}/>
                    <TextField source="name" emptyText={'N/A'}/>
                    <ReferenceField source="country_id" reference="country" emptyText={'N/A'}><TextField source="name" /></ReferenceField>
                    <ReferenceField source="state_id" reference="state" emptyText={'N/A'}><TextField source="name" /></ReferenceField>
                    <TextField source="default_activity_type" emptyText={'N/A'}/>
                    <TextField source="total_activities" emptyText={'0'}/>
                    <NumberField source="total_distance" emptyText={'0'}/>
                    <NumberField source="total_elevation_gain" emptyText={'0'}/>
                    <NumberField source="total_calories_burned" emptyText={'0'}/>

                    <BooleanField source="is_paid" />
                    <BooleanField source="is_registration_completed" />
                    <BooleanField source="email_consent" />
                    <NumberField source="sessions_count" emptyText={'0'}/>
                    <BooleanField source="is_nps_sent" emptyText={'N/A'}/>
                    <ChipList
                        source="reasons"
                        sortable={false}
                        normalizer={(obj: any) => obj.replace('_', ' ')}
                    />
                    <DateField source="created_at" showTime/>
                    <DateField source="updated_at" showTime/>
                    <ShowButton/>
                    <EditButton/>
                </List>
            </If>
        </When>
        <Otherwise>
            <Loader/>
        </Otherwise>
    </Choose>
)


export default withServerConstants(User);
