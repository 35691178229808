import React from 'react';
import {
    EditButton,
    FunctionField,
    NumberField,
    ReferenceField,
    ShowButton,
    TextField,
} from 'react-admin';
import List from 'components/List';
import {ControllerPropsInterface} from "interfaces/ControllerPropsInterface";
import Loader from "components/Loader";

import {Choose, Otherwise, When} from 'typings/tsx-control-statements.d';
import {withServerConstants} from "../../providers/ServerConstantsProvider";
import ParkFilter from "../../filters/Trail/ParkFilter";
import {ParkTypeChip} from "../../components/Chip/ParkTypeChip";
import {TRAIL_GOAL_BY_TRAIL_TYPE} from "../../constants/park";
import {Record} from "ra-core";
import {getItemById} from "../../utils/common";

const rowStyle = (record: any) => ({
    borderLeftColor: '#333',
    borderLeftWidth: 10,
    borderLeftStyle: 'solid',
});


const Park = (props: ControllerPropsInterface) => (
    <Choose>
        <When condition={true}>
            <List
                filters={<ParkFilter serverConstants={props.serverConstants}/>}
                filterDefaultValues={{country_id: 205}}
                rowStyle={(record) => rowStyle(record)}
                {...props}
            >
                <TextField source="name"/>
                <ParkTypeChip source="type" serverConstants={props.serverConstants}/>
                <ReferenceField source="country_id" reference="country">
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceField source="state_id" reference="state">
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceField source="city_id" reference="city">
                    <TextField source="name"/>
                </ReferenceField>
                <NumberField source="trails_count"/>
                <NumberField source="trails_count_goal"/>
                <FunctionField
                    source="trails_to_create"
                    render={(record: Record | undefined) => record && record.trails_count_goal > 0 ? record.trails_count_goal - record.trails_count : 0}
                />
                <NumberField source=""/>
                <FunctionField
                    source="coverage"
                    render={(record: Record | undefined) => record && record.trails_count_goal > 0 ? Math.round(record.trails_count / record.trails_count_goal * 100) + '%' : 'N/A'}
                    label="% of coverage"
                />
                <ShowButton/>
                <EditButton/>
            </List>
        </When>
        <Otherwise>
            <Loader/>
        </Otherwise>
    </Choose>
)


export default withServerConstants(Park);
