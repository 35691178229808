import AbstractNormalizer from './AbstractNormalizer';

class TrailNormalizer extends AbstractNormalizer {
    create(payload: any) {
        const data = payload;
        data['duration'] = Math.round(data['duration'])
        data['length'] = Math.round(data['length'])
        return data;
    }

    update(payload: any) {
        const data = super.beforeUpdate(payload);
        data['duration'] = Math.round(data['duration'])
        data['length'] = Math.round(data['length'])
        delete data['nearby_trails']
        delete data['reviews']
        return data;
    }
}

export default new TrailNormalizer();
