import React from 'react';
import {
  MenuItemLink,
  ReduxState,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// @ts-ignore
import classnames from 'classnames';
import { useMediaQuery } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import SubMenu from "./SubMenu";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import FlagIcon from "@material-ui/icons/Flag";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import PublicIcon from "@material-ui/icons/Public";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import FilterHdrIcon from '@material-ui/icons/FilterHdr';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import NatureIcon from '@material-ui/icons/Nature';
import StarIcon from '@material-ui/icons/Star';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    width: 250,
  },
  closed: {
    width: 55,
  },
}));


const Menu = ({ onMenuClick, logout, dense = false, options }: any) => {
  const sidebarIsOpen = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const classes = useStyles();
  const isXSmall = useMediaQuery((theme: any) =>
    theme.breakpoints.down('xs')
  );

  return (
    <div
      className={classnames(classes.root, {
        [classes.open]: sidebarIsOpen,
        [classes.closed]: !sidebarIsOpen,
      })}
    >
      <SubMenu
        sidebarIsOpen={sidebarIsOpen}
        name="Content"
        icon={<PhotoLibraryIcon />}
        dense={dense}
        options={options}
      >
        <MenuItemLink
          key={`trail`}
          to={`/trail`}
          primaryText={`Main Trails`}
          leftIcon={<ListIcon />}
          onClick={onMenuClick}
          dense={dense}
        />
        <MenuItemLink
          key={`external-trail`}
          to={`/external-trail`}
          primaryText={`External Trails`}
          leftIcon={<FilterHdrIcon />}
          onClick={onMenuClick}
          dense={dense}
        />
        <MenuItemLink
            key={`country`}
            to={`/country`}
            primaryText={`Country`}
            leftIcon={<PublicIcon />}
            onClick={onMenuClick}
            dense={dense}
        />
        <MenuItemLink
            key={`region`}
            to={`/state`}
            primaryText={`Regions`}
            leftIcon={<FlagIcon />}
            onClick={onMenuClick}
            dense={dense}
        />
        <MenuItemLink
            key={`city`}
            to={`/city`}
            primaryText={`City`}
            leftIcon={<LocationCityIcon />}
            onClick={onMenuClick}
            dense={dense}
        />
        <MenuItemLink
            key={`park`}
            to={`/park`}
            primaryText={`Park`}
            leftIcon={<NatureIcon />}
            onClick={onMenuClick}
            dense={dense}
        />
        <MenuItemLink
            key={`trail_review`}
            to={`/trail-review`}
            primaryText={`Trail Review`}
            leftIcon={<StarIcon />}
            onClick={onMenuClick}
            dense={dense}
        />
      </SubMenu>
      <SubMenu
          sidebarIsOpen={sidebarIsOpen}
          name="Management"
          icon={<DesktopWindowsIcon />}
          dense={dense}
          options={options}
      >
        <MenuItemLink
            key={`user`}
            to={`/user`}
            primaryText={`User`}
            leftIcon={<SupervisedUserCircleIcon />}
            onClick={onMenuClick}
            dense={dense}
        />
        <MenuItemLink
            key={`user-activity`}
            to={`/user-activity`}
            primaryText={`User Activity`}
            leftIcon={<CallSplitIcon />}
            onClick={onMenuClick}
            dense={dense}
        />
      </SubMenu>
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
