import {
    TextInput,
    required, NumberInput, maxValue, minValue,
} from "react-admin";
import {withServerConstants} from "providers/ServerConstantsProvider";
import React from "react";
import SimpleFormCustom from "../SimpleFormCustom";
import {ActionType} from "../../interfaces/CommonInterface";
import {EditableImage} from "../../components/Image/EditableImageField";


const CountryForm = (props: any) => {
    return (
        <SimpleFormCustom
            isLoaded={!!props.record}
            {...props}
        >
            <TextInput source="name" validate={[required('Name is required')]}/>
            <TextInput
                multiline
                rows={5}
                source="description"
                fullWidth
                validate={[required('Description is required')]}
            />
            <EditableImage source="preview_img" {...props} label="Image" aspect={1.74}/>
            <TextInput source="slug" validate={[required('Slug is required')]}/>
            <TextInput source="iso_code" validate={[required('Iso Code is required')]}/>
            <NumberInput source="latitude"
                         min="-90"
                         max="90"
                         validate={[
                             required('Latitude is required'),
                             minValue(-90, 'Must be more than -90'),
                             maxValue(90, 'Must be less than 90'),
                         ]} />
            <NumberInput source="longitude"
                         min="-180"
                         max="180"
                         validate={[
                             required('Longitude is required'),
                             minValue(-180, 'Must be more than -180'),
                             maxValue(180, 'Must be less than 180'),
                         ]} />
            <NumberInput source="popularity"/>
        </SimpleFormCustom>
    )
};

export default withServerConstants(CountryForm)
