import AbstractNormalizer from './AbstractNormalizer';

class ParkNormalizer extends AbstractNormalizer {
    create(payload: any) {
        const data = payload;
        return data;
    }

    update(payload: any) {
        const data = super.beforeUpdate(payload, true);
        delete data['trails'];
        delete data['trails_count'];
        delete data['area_polygon_polyline'];
        delete data['latitude_bottom_right'];
        delete data['latitude_top_left'];
        delete data['longitude_bottom_right'];
        delete data['longitude_top_left'];
        delete data['operating_hours'];
        delete data['entrance_fees'];
        delete data['contact_info'];
        delete data['activities'];
        delete data['location_key'];
        delete data['code'];
        delete data['images'];
        // console.log([data['preview_img'],data['preview_img']])

        // data['images'] = [data['preview_img']]
        return data;
    }
}

export default new ParkNormalizer();
