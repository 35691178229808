import React from 'react';
import {Filter, FormDataConsumer, TextInput, NullableBooleanInput, SelectInput} from 'react-admin';
import {renderFilterReferenceInput} from "../FiltersUtils";

const TrailReviewFilter = (props: any) => {
  const { serverConstants, ...rest } = props;

  return (
    <Filter {...rest}>
        <TextInput source="id" alwaysOn resettable />
        <TextInput source="trail_id" alwaysOn resettable />
        <TextInput source="user_id" alwaysOn resettable />
        <TextInput source="message" alwaysOn resettable />
        {renderFilterReferenceInput('Country', 'country_id', 'country')}
        <FormDataConsumer form={'filterForm'} alwaysOn source='state_id'>
            {({ formData }) => renderFilterReferenceInput(
                'Region',
                'state_id',
                'state',
                props.filterValues.country_id ? { country_id: props.filterValues.country_id } : {},
                (searchText: any) => (searchText ? {name: searchText} : {}),
                !formData.country_id
            )}
        </FormDataConsumer>
        <FormDataConsumer form={'filterForm'} alwaysOn source='city_id'>
            {({ formData }) => renderFilterReferenceInput(
                'City',
                'city_id',
                'city',
                props.filterValues.state_id ? { state_id: props.filterValues.state_id } : {},
                (searchText: any) => (searchText ? {name: searchText} : {}),
                !formData.state_id
            )}
        </FormDataConsumer>
        <FormDataConsumer form={'filterForm'} alwaysOn source='park_id'>
            {({ formData }) => renderFilterReferenceInput(
                'Park',
                'park_id',
                'park',
                props.filterValues.state_id ? { state_id: props.filterValues.state_id } : {},
                (searchText: any) => (searchText ? {name: searchText} : {}),
                !formData.state_id
            )}
        </FormDataConsumer>
        <NullableBooleanInput source="is_not_empty" label="Is Not empty Message" alwaysOn resettable />
        <SelectInput source="rating" allowEmpty
                     choices={[
                         {
                             id: 1,
                             name: '1'
                         },
                         {
                             id: 2,
                             name: '2'
                         },
                         {
                             id: 3,
                             name: '3'
                         },
                         {
                             id: 4,
                             name: '4'
                         },
                         {
                             id: 5,
                             name: '5'
                         }
                     ]} label="Rating"
                     alwaysOn resettable/>
    </Filter >
  );
};

export default TrailReviewFilter;
